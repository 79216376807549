import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_ContentContainer = _resolveComponent("ContentContainer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Sidebar),
    _createVNode(_component_ContentContainer)
  ]))
}